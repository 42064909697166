html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}

d-none {
  display: none;
}

.text-center {
  text-align: center;
}

router-outlet + * {
  width: 100%;
}

.flex {
  display: flex;
}

a {
  color: white;

}

a:visited {
  color: antiquewhite;
}

.bold {
  font-weight: bold;
}

.mat-form-field {
  padding: 0.2rem 0.25rem !important;
}